export const AutomatedIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.822 11.1708H12.742C12.2553 11.1708 11.862 10.7804 11.862 10.2973V7.91511C11.862 7.27324 11.6953 6.63799 11.382 6.08214C11.162 5.68511 11.162 5.21529 11.3953 4.82487C11.6287 4.43445 12.0353 4.20285 12.4953 4.20285H18.7287C20.962 4.20285 22.782 6.00935 22.782 8.22612V10.026C22.782 10.5091 22.3887 10.8995 21.902 10.8995C21.4153 10.8995 21.022 10.5091 21.022 10.026V8.76874C21.022 8.59669 20.942 8.44449 20.7953 8.35185C20.6487 8.25921 20.4753 8.24598 20.322 8.31215C19.0887 8.86138 17.7753 9.1393 16.4287 9.1393H16.2687C15.402 9.1393 14.5287 8.97387 13.7153 8.66286V10.2973C13.702 10.7804 13.3087 11.1708 12.822 11.1708ZM12.4953 4.89766C12.2887 4.89766 12.102 5.00353 12.002 5.1822C11.8953 5.36087 11.8953 5.57262 11.9953 5.75128C12.3687 6.413 12.5687 7.16737 12.5687 7.92835V10.3106C12.5687 10.4098 12.6487 10.4892 12.7487 10.4892H12.8287C12.9287 10.4892 13.0087 10.4098 13.0087 10.3106V8.14672C13.0087 8.02761 13.0687 7.91511 13.1753 7.85556C13.2753 7.78939 13.402 7.78277 13.5153 7.83571C14.3687 8.24598 15.3153 8.46434 16.2687 8.46434H16.4287C17.682 8.46434 18.8953 8.20627 20.0353 7.69675C20.4087 7.53132 20.8353 7.5644 21.1753 7.78277C21.5153 8.00114 21.722 8.37832 21.722 8.78197V10.0392C21.722 10.1385 21.802 10.2179 21.902 10.2179C22.002 10.2179 22.082 10.1385 22.082 10.0392V8.23936C22.082 6.40639 20.5753 4.91089 18.7287 4.91089H12.4953V4.89766Z"
        fill="url(#paint0_linear_2140_11991)"
        stroke="url(#paint1_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M16.6767 7.70889C15.9701 7.70889 14.9634 7.62286 14.0501 7.21921C13.8701 7.1398 13.7967 6.93467 13.8701 6.75601C13.9501 6.58396 14.1567 6.50455 14.3301 6.57734C15.7367 7.19936 17.4901 6.96114 17.5101 6.96114C17.6967 6.93467 17.8767 7.06702 17.9034 7.2523C17.9301 7.4442 17.7967 7.61625 17.6101 7.64271C17.5767 7.65595 17.2101 7.70889 16.6767 7.70889Z"
        fill="url(#paint2_linear_2140_11991)"
        stroke="url(#paint3_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M17.8104 16.8612H16.7637C15.9504 16.8612 15.1704 16.6693 14.5237 16.2987C13.1437 15.5113 12.2837 14.0555 12.2837 12.4872V10.8197C12.2837 10.7138 12.3304 10.6145 12.417 10.5484C12.497 10.4822 12.6037 10.4557 12.7104 10.4822C12.7237 10.4822 12.737 10.4888 12.7504 10.4888H12.8304C12.9304 10.4888 13.0104 10.4094 13.0104 10.3101V8.1463C13.0104 8.02719 13.0704 7.9147 13.177 7.85514C13.277 7.78897 13.4037 7.78235 13.517 7.83529C14.3704 8.24556 15.317 8.46393 16.2704 8.46393H16.4304C17.5504 8.46393 18.7504 8.20585 20.0904 7.67648C20.457 7.5309 20.877 7.57722 21.2037 7.79559C21.5304 8.01395 21.7304 8.38452 21.7304 8.78155V10.0388C21.7304 10.1315 21.8037 10.2109 21.9037 10.2175C22.0904 10.2241 22.237 10.3763 22.237 10.5616V12.4276C22.237 13.0033 22.1437 13.5327 21.9704 14.0025C21.297 15.7363 19.6704 16.8612 17.8104 16.8612ZM12.9837 11.1571V12.4806C12.9837 13.7974 13.7104 15.0282 14.8704 15.6899C15.417 16.0009 16.0704 16.1598 16.7637 16.1598H17.8104C19.377 16.1598 20.7437 15.2135 21.297 13.7445C21.4437 13.3474 21.5237 12.8975 21.5237 12.4078V10.8064C21.2237 10.6675 21.017 10.3631 21.017 10.019V8.7617C21.017 8.59627 20.937 8.44407 20.797 8.35143C20.657 8.25879 20.4904 8.23894 20.337 8.29849C18.917 8.86096 17.637 9.13227 16.4237 9.13227H16.2637C15.397 9.13227 14.5237 8.96683 13.7104 8.65582V10.2903C13.7037 10.727 13.397 11.0843 12.9837 11.1571Z"
        fill="url(#paint4_linear_2140_11991)"
        stroke="url(#paint5_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M1.34935 29.0901C1.30935 29.0901 1.26935 29.0835 1.22935 29.0703C1.07602 29.0107 0.982685 28.8519 1.00268 28.6931C1.00935 28.66 1.50935 25.0999 2.67602 21.7913C3.23602 20.1966 3.12268 18.4695 2.36935 17.0468C1.63602 15.6638 1.26268 14.208 1.26268 12.7059C1.26935 6.25408 8.28268 1 16.9027 1C25.5227 1 32.536 6.25408 32.536 12.7125C32.536 19.1709 25.5227 24.425 16.9027 24.425C15.2227 24.425 13.5694 24.2265 11.9894 23.8361C10.6894 23.5184 9.28935 23.6309 8.04269 24.1669C4.99602 25.4639 2.84935 27.0653 1.64935 28.9313C1.58268 29.0306 1.46935 29.0901 1.34935 29.0901ZM16.9027 1.69481C8.66935 1.69481 1.96935 6.63788 1.96935 12.7125C1.96935 14.0955 2.31602 15.452 2.99602 16.7291C3.84268 18.3173 3.96935 20.2429 3.34268 22.0229C2.64268 24.0081 2.18935 26.0859 1.93602 27.4094C3.26935 25.9139 5.22268 24.6103 7.76935 23.525C9.15602 22.9361 10.716 22.8038 12.1627 23.1611C13.6894 23.5383 15.2827 23.7302 16.9027 23.7302C25.136 23.7302 31.836 18.7871 31.836 12.7125C31.836 6.63788 25.136 1.69481 16.9027 1.69481Z"
        fill="url(#paint6_linear_2140_11991)"
        stroke="url(#paint7_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M16.9016 24.4181C15.2216 24.4181 13.5683 24.2196 11.9883 23.8292C11.4483 23.6969 10.8749 23.6373 10.2949 23.6572C10.2016 23.6638 10.1083 23.6241 10.0416 23.5579C9.97494 23.4917 9.93494 23.3991 9.93494 23.3064V21.4007C9.93494 19.7199 11.3149 18.3501 13.0083 18.3501H14.9749C15.0616 18.3501 15.1349 18.2774 15.1349 18.1913V16.3187C15.1349 16.2062 15.1883 16.1069 15.2749 16.0407C15.3616 15.9746 15.4816 15.9547 15.5883 15.9878C15.9616 16.1003 16.3616 16.1598 16.7749 16.1598H17.8216C18.2016 16.1598 18.5749 16.1003 18.9349 15.9878C19.0416 15.9547 19.1549 15.9746 19.2483 16.0407C19.3349 16.1069 19.3883 16.2128 19.3883 16.3187V18.1913C19.3883 18.2774 19.4616 18.3501 19.5483 18.3501H21.5149C23.2083 18.3501 24.5883 19.7199 24.5883 21.4007V22.6778C24.5883 22.8168 24.5016 22.9425 24.3683 23.0021C22.0816 23.9285 19.5016 24.4181 16.9016 24.4181ZM10.6349 22.9624C11.1616 22.969 11.6683 23.0351 12.1616 23.1543C13.6883 23.5314 15.2816 23.7233 16.9016 23.7233C19.3349 23.7233 21.7349 23.28 23.8749 22.4462V21.4007C23.8749 20.1037 22.8083 19.045 21.5016 19.045H19.5349C19.0616 19.045 18.6816 18.6612 18.6816 18.1979V16.7752C18.3949 16.8348 18.1083 16.8613 17.8149 16.8613H16.7683C16.4483 16.8613 16.1349 16.8282 15.8283 16.7686V18.1979C15.8283 18.6678 15.4416 19.045 14.9749 19.045H13.0083C11.7016 19.045 10.6349 20.1037 10.6349 21.4007V22.9624Z"
        fill="url(#paint8_linear_2140_11991)"
        stroke="url(#paint9_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M10.2831 16.1862H5.52308C5.32975 16.1862 5.17642 16.034 5.17642 15.8421C5.17642 15.6502 5.32975 15.498 5.52308 15.498H10.2831C10.4764 15.498 10.6298 15.6502 10.6298 15.8421C10.6364 16.034 10.4764 16.1862 10.2831 16.1862Z"
        fill="url(#paint10_linear_2140_11991)"
        stroke="url(#paint11_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M10.2831 12.0238H5.52308C5.32975 12.0238 5.17642 11.8716 5.17642 11.6797C5.17642 11.4878 5.32975 11.3356 5.52308 11.3356H10.2831C10.4764 11.3356 10.6298 11.4878 10.6298 11.6797C10.6364 11.8716 10.4764 12.0238 10.2831 12.0238Z"
        fill="url(#paint12_linear_2140_11991)"
        stroke="url(#paint13_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M28.9817 16.1862H24.2217C24.0283 16.1862 23.875 16.034 23.875 15.8421C23.875 15.6502 24.0283 15.498 24.2217 15.498H28.9817C29.175 15.498 29.3283 15.6502 29.3283 15.8421C29.335 16.034 29.175 16.1862 28.9817 16.1862Z"
        fill="url(#paint14_linear_2140_11991)"
        stroke="url(#paint15_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M28.9817 12.0238H24.2217C24.0283 12.0238 23.875 11.8716 23.875 11.6797C23.875 11.4878 24.0283 11.3356 24.2217 11.3356H28.9817C29.175 11.3356 29.3283 11.4878 29.3283 11.6797C29.335 11.8716 29.175 12.0238 28.9817 12.0238Z"
        fill="url(#paint16_linear_2140_11991)"
        stroke="url(#paint17_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M39.3431 38.3091H28.7365C27.3565 38.3091 26.2365 37.1974 26.2365 35.8276V29.9449C26.2365 28.2377 27.6365 26.8414 29.3565 26.8414H38.7165C40.4365 26.8414 41.8365 28.231 41.8365 29.9449V35.8276C41.8431 37.1974 40.7231 38.3091 39.3431 38.3091ZM29.3565 27.5362C28.0165 27.5362 26.9298 28.6148 26.9298 29.9449V35.8276C26.9298 36.8136 27.7365 37.6143 28.7298 37.6143H39.3365C40.3298 37.6143 41.1365 36.8136 41.1365 35.8276V29.9449C41.1365 28.6148 40.0498 27.5362 38.7098 27.5362H29.3565Z"
        fill="url(#paint18_linear_2140_11991)"
        stroke="url(#paint19_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M38.8434 36.6947H29.2301C28.3434 36.6947 27.6234 35.98 27.6234 35.1L27.6167 30.9311C27.6167 30.8385 27.6567 30.7524 27.7167 30.6863C27.7834 30.6201 27.8701 30.587 27.9634 30.587H40.0967C40.2901 30.587 40.4434 30.7392 40.4434 30.9311L40.4501 35.1C40.4567 35.98 39.7367 36.6947 38.8434 36.6947ZM28.3167 31.2752L28.3234 35.1C28.3234 35.5962 28.7301 36.0065 29.2367 36.0065H38.8501C39.3501 36.0065 39.7634 35.6029 39.7634 35.1L39.7567 31.2752H28.3167Z"
        fill="url(#paint20_linear_2140_11991)"
        stroke="url(#paint21_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M41.4102 29.6801H26.6568C26.4635 29.6801 26.3102 29.5279 26.3102 29.336C26.3102 29.1441 26.4635 28.9919 26.6568 28.9919H41.4168C41.6102 28.9919 41.7635 29.1441 41.7635 29.336C41.7635 29.5213 41.6035 29.6801 41.4102 29.6801Z"
        fill="url(#paint22_linear_2140_11991)"
        stroke="url(#paint23_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M30.4826 34.4438C29.7692 34.4438 29.1826 33.8681 29.1826 33.1534C29.1826 32.4454 29.7626 31.8631 30.4826 31.8631C31.1959 31.8631 31.7826 32.4388 31.7826 33.1534C31.7826 33.8681 31.1959 34.4438 30.4826 34.4438ZM30.4826 32.5645C30.1559 32.5645 29.8826 32.8292 29.8826 33.16C29.8826 33.4909 30.1492 33.7556 30.4826 33.7556C30.8159 33.7556 31.0826 33.4909 31.0826 33.16C31.0826 32.8292 30.8159 32.5645 30.4826 32.5645Z"
        fill="url(#paint24_linear_2140_11991)"
        stroke="url(#paint25_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M37.5975 34.4438C36.8841 34.4438 36.2975 33.8681 36.2975 33.1534C36.2975 32.4454 36.8775 31.8631 37.5975 31.8631C38.3108 31.8631 38.8975 32.4388 38.8975 33.1534C38.8908 33.8681 38.3108 34.4438 37.5975 34.4438ZM37.5975 32.5645C37.2708 32.5645 36.9975 32.8292 36.9975 33.16C36.9975 33.4909 37.2641 33.7556 37.5975 33.7556C37.9308 33.7556 38.1975 33.4909 38.1975 33.16C38.1975 32.8292 37.9241 32.5645 37.5975 32.5645Z"
        fill="url(#paint26_linear_2140_11991)"
        stroke="url(#paint27_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M35.5766 39.8559H32.5033C32.31 39.8559 32.1566 39.7037 32.1566 39.5118V37.9634C32.1566 37.7715 32.31 37.6193 32.5033 37.6193H35.5766C35.77 37.6193 35.9233 37.7715 35.9233 37.9634V39.5118C35.9233 39.7037 35.77 39.8559 35.5766 39.8559ZM32.85 39.1677H35.2233V38.3141H32.85V39.1677Z"
        fill="url(#paint28_linear_2140_11991)"
        stroke="url(#paint29_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M49.4551 51.0004C49.3417 51.0004 49.2284 50.9409 49.1617 50.8416C47.9617 48.9756 45.8084 47.3742 42.7684 46.0772C41.5217 45.5478 40.1151 45.4287 38.8217 45.7464C37.2417 46.1368 35.5884 46.3353 33.9084 46.3353C25.2884 46.3353 18.2751 41.0812 18.2751 34.6228C18.2751 28.1644 25.2884 22.9103 33.9084 22.9103C42.5284 22.9103 49.5417 28.1644 49.5417 34.6228C49.5417 36.1249 49.1684 37.5807 48.4351 38.9637C47.6817 40.3798 47.5684 42.1069 48.1284 43.7083C49.2884 47.0169 49.7951 50.5769 49.8017 50.61C49.8217 50.7688 49.7284 50.9276 49.5751 50.9806C49.5351 50.9938 49.4951 51.0004 49.4551 51.0004ZM40.2817 44.8729C41.2217 44.8729 42.1617 45.0648 43.0351 45.4353C45.5817 46.5206 47.5351 47.8242 48.8684 49.3197C48.6217 47.9962 48.1617 45.9184 47.4617 43.9332C46.8351 42.1532 46.9617 40.2276 47.8084 38.6395C48.4884 37.3623 48.8351 36.0124 48.8351 34.6228C48.8351 28.5482 42.1351 23.6051 33.9017 23.6051C25.6684 23.6051 18.9684 28.5482 18.9684 34.6228C18.9684 40.6974 25.6684 45.6405 33.9084 45.6405C35.5284 45.6405 37.1284 45.4486 38.6484 45.0714C39.1817 44.9391 39.7351 44.8729 40.2817 44.8729Z"
        fill="url(#paint30_linear_2140_11991)"
        stroke="url(#paint31_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M33.909 46.3277C32.2823 46.3277 30.6756 46.1424 29.129 45.7652C28.969 45.7255 28.8623 45.5866 28.8623 45.4278V40.4251C28.8623 39.7237 29.4356 39.1546 30.1423 39.1546H37.949C38.6556 39.1546 39.229 39.7237 39.229 40.4251V45.3682C39.229 45.527 39.1156 45.6726 38.9556 45.7057L38.829 45.7322C37.2356 46.1358 35.5823 46.3277 33.909 46.3277ZM29.5556 45.1565C32.469 45.8116 35.6423 45.7851 38.5156 45.0969V40.4318C38.5156 40.1141 38.2556 39.8561 37.9356 39.8561H30.1356C29.8156 39.8561 29.5556 40.1141 29.5556 40.4318V45.1565Z"
        fill="url(#paint32_linear_2140_11991)"
        stroke="url(#paint33_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M24.2218 33.1664H21.5018C21.3084 33.1664 21.1551 33.0142 21.1551 32.8223C21.1551 32.6304 21.3084 32.4782 21.5018 32.4782H24.2218C24.4151 32.4782 24.5684 32.6304 24.5684 32.8223C24.5751 33.0142 24.4151 33.1664 24.2218 33.1664Z"
        fill="url(#paint34_linear_2140_11991)"
        stroke="url(#paint35_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M24.2218 36.2498H21.5018C21.3084 36.2498 21.1551 36.0976 21.1551 35.9057C21.1551 35.7138 21.3084 35.5616 21.5018 35.5616H24.2218C24.4151 35.5616 24.5684 35.7138 24.5684 35.9057C24.5751 36.0976 24.4151 36.2498 24.2218 36.2498Z"
        fill="url(#paint36_linear_2140_11991)"
        stroke="url(#paint37_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M46.291 33.1664H43.571C43.3776 33.1664 43.2243 33.0142 43.2243 32.8223C43.2243 32.6304 43.3776 32.4782 43.571 32.4782H46.291C46.4843 32.4782 46.6376 32.6304 46.6376 32.8223C46.6443 33.0142 46.4843 33.1664 46.291 33.1664Z"
        fill="url(#paint38_linear_2140_11991)"
        stroke="url(#paint39_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <path
        d="M46.291 36.2498H43.571C43.3776 36.2498 43.2243 36.0976 43.2243 35.9057C43.2243 35.7138 43.3776 35.5616 43.571 35.5616H46.291C46.4843 35.5616 46.6376 35.7138 46.6376 35.9057C46.6443 36.0976 46.4843 36.2498 46.291 36.2498Z"
        fill="url(#paint40_linear_2140_11991)"
        stroke="url(#paint41_linear_2140_11991)"
        strokeWidth="0.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2140_11991"
          x1="17.0003"
          y1="4.20285"
          x2="17.0003"
          y2="11.1708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2140_11991"
          x1="17.0003"
          y1="4.20285"
          x2="17.0003"
          y2="11.1708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2140_11991"
          x1="15.8744"
          y1="6.55035"
          x2="15.8744"
          y2="7.70889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2140_11991"
          x1="15.8744"
          y1="6.55035"
          x2="15.8744"
          y2="7.70889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2140_11991"
          x1="17.2604"
          y1="7.59253"
          x2="17.2604"
          y2="16.8612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2140_11991"
          x1="17.2604"
          y1="7.59253"
          x2="17.2604"
          y2="16.8612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2140_11991"
          x1="16.768"
          y1="1"
          x2="16.768"
          y2="29.0901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2140_11991"
          x1="16.768"
          y1="1"
          x2="16.768"
          y2="29.0901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2140_11991"
          x1="17.2616"
          y1="15.9721"
          x2="17.2616"
          y2="24.4181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2140_11991"
          x1="17.2616"
          y1="15.9721"
          x2="17.2616"
          y2="24.4181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2140_11991"
          x1="7.90319"
          y1="15.498"
          x2="7.90319"
          y2="16.1862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2140_11991"
          x1="7.90319"
          y1="15.498"
          x2="7.90319"
          y2="16.1862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2140_11991"
          x1="7.90319"
          y1="11.3356"
          x2="7.90319"
          y2="12.0238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2140_11991"
          x1="7.90319"
          y1="11.3356"
          x2="7.90319"
          y2="12.0238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2140_11991"
          x1="26.6018"
          y1="15.498"
          x2="26.6018"
          y2="16.1862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_2140_11991"
          x1="26.6018"
          y1="15.498"
          x2="26.6018"
          y2="16.1862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_2140_11991"
          x1="26.6018"
          y1="11.3356"
          x2="26.6018"
          y2="12.0238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_2140_11991"
          x1="26.6018"
          y1="11.3356"
          x2="26.6018"
          y2="12.0238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_2140_11991"
          x1="34.0365"
          y1="26.8414"
          x2="34.0365"
          y2="38.3091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_2140_11991"
          x1="34.0365"
          y1="26.8414"
          x2="34.0365"
          y2="38.3091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_2140_11991"
          x1="34.0334"
          y1="30.587"
          x2="34.0334"
          y2="36.6947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_2140_11991"
          x1="34.0334"
          y1="30.587"
          x2="34.0334"
          y2="36.6947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_2140_11991"
          x1="34.0368"
          y1="28.9919"
          x2="34.0368"
          y2="29.6801"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_2140_11991"
          x1="34.0368"
          y1="28.9919"
          x2="34.0368"
          y2="29.6801"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_2140_11991"
          x1="30.4826"
          y1="31.8631"
          x2="30.4826"
          y2="34.4438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_2140_11991"
          x1="30.4826"
          y1="31.8631"
          x2="30.4826"
          y2="34.4438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_2140_11991"
          x1="37.5975"
          y1="31.8631"
          x2="37.5975"
          y2="34.4438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_2140_11991"
          x1="37.5975"
          y1="31.8631"
          x2="37.5975"
          y2="34.4438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_2140_11991"
          x1="34.04"
          y1="37.6193"
          x2="34.04"
          y2="39.8559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_2140_11991"
          x1="34.04"
          y1="37.6193"
          x2="34.04"
          y2="39.8559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_2140_11991"
          x1="34.0397"
          y1="22.9103"
          x2="34.0397"
          y2="51.0004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_2140_11991"
          x1="34.0397"
          y1="22.9103"
          x2="34.0397"
          y2="51.0004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_2140_11991"
          x1="34.0456"
          y1="39.1546"
          x2="34.0456"
          y2="46.3277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_2140_11991"
          x1="34.0456"
          y1="39.1546"
          x2="34.0456"
          y2="46.3277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_2140_11991"
          x1="22.8619"
          y1="32.4782"
          x2="22.8619"
          y2="33.1664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_2140_11991"
          x1="22.8619"
          y1="32.4782"
          x2="22.8619"
          y2="33.1664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_2140_11991"
          x1="22.8619"
          y1="35.5616"
          x2="22.8619"
          y2="36.2498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_2140_11991"
          x1="22.8619"
          y1="35.5616"
          x2="22.8619"
          y2="36.2498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_2140_11991"
          x1="44.9311"
          y1="32.4782"
          x2="44.9311"
          y2="33.1664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_2140_11991"
          x1="44.9311"
          y1="32.4782"
          x2="44.9311"
          y2="33.1664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_2140_11991"
          x1="44.9311"
          y1="35.5616"
          x2="44.9311"
          y2="36.2498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_2140_11991"
          x1="44.9311"
          y1="35.5616"
          x2="44.9311"
          y2="36.2498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
      </defs>
    </svg>
  );
};
