import React from 'react';
import { Container, Content, Cta, Section, Title } from './section';
import {
  HourIcon,
  MagnetIcon,
  RocketIcon,
  TimeIcon,
  TradeIcon
} from '@/assets/icons';
import Button from './button';
import { FeatureShadow } from '@/assets/shadow';
import useShowMore from '@/utils/useShowMore';
import { Card, Head, Paragraph } from '@/components/card';
import { PlayVideo } from './hero';
import { ChannelsIcon } from '@/assets/icons/channels';
import { CustomisedIcon } from '@/assets/icons/customised';
interface ItemProps {
  description: string;
  icon: React.ReactNode;
  tag: string;
  title: string | React.ReactNode;
}

const Item: React.FC<{ item: ItemProps }> = ({ item }) => {
  const { toggle, isMore } = useShowMore(item.description, 100);
  return (
    <Card>
      <div className="mb-8 flex items-center">
        {item.icon}
        <Button
          className="gradient-button-bg-1 px-4 text-sm font-light tracking-[-0.065em] sm:px-8 sm:py-3 sm:text-left lg:whitespace-nowrap lg:px-8 lg:text-center lg:text-[20px] xl:text-[26px]"
          style={{ cursor: 'default' }}
        >
          {item.tag}
        </Button>
      </div>
      <Head className="mt-4">{item.title}</Head>
      <Paragraph className="mt-6">
        {/* {str} */}
        {item.description}
      </Paragraph>
      <div className="mt-6 hidden w-full items-center justify-end">
        <button
          className="leading-0 cursor-pointer align-bottom font-medium text-orange"
          onClick={toggle}
        >
          {isMore ? 'Show less' : 'Show more'}
        </button>
      </div>
    </Card>
  );
};

const Feature: React.FC = () => {
  const data: ItemProps[] = [
    {
      description:
        "Nestflo offers immediate replies around the clock, every day of the week, to all inquiries through accurate and insightful conversations. Your business stays ahead of the game. By addressing all prospective customer inquiries without fail, your business's reputation will improve significantly.",
      icon: (
        <HourIcon className="mr-3 w-6 shrink-0 sm:w-[45px] lg:mr-4 xl:mr-6 xl:w-14" />
      ),
      tag: 'Stay ahead competition',
      title: <span>Stay ahead with 24/7 instant responses</span>
    },
    {
      description:
        'Nestflo helps your business become more efficient by saving the time your staff spends manually responding to and pre-qualifying prospective tenants, as well as automatically booking viewings into an agent’s calendar. This frees up their bandwidth for high-value tasks, which, in turn, can increase revenue.',
      icon: <RocketIcon className="mr-6 w-8 shrink-0 lg:w-14" />,
      tag: 'More efficient',
      title: <span>Create time for high value tasks</span>
    },
    {
      description:
        'Nestflo makes it easy to organize lead data from multiple channels, like Rightmove, Zoopla, OnTheMarket, Spareroom, Openrent, and Gumtree, all in one streamlined system. By automating lead capture and centralizing information, it helps letting agencies stay organized, save time, and never miss an opportunity.',
      icon: <MagnetIcon className="mr-6 w-8 shrink-0 lg:w-14" />,
      tag: 'Manage Leads Across Multiple Channels',
      title: <span>Easily organise lead data from multiple channels</span>
    },
    {
      description: `Nestflo is a highly customizable system that allows letting agencies to tailor the tenant pre-qualification process to their specific needs. Agencies can adjust criteria, set preferences for how leads are handled, and personalize communication with prospective tenants.`,
      icon: <CustomisedIcon className="mr-6 w-8 shrink-0 lg:w-14" />,
      tag: 'Customised system',
      title: <span>Customisable pre-qualification and communication</span>
    }
  ];

  return (
    <Section id="link_1">
      <picture className="pointer-events-none absolute inset-x-0 top-[-300px] -z-20">
        <FeatureShadow className="h-auto w-full" />
      </picture>
      <Container className="max-w-[1467px]">
        <Title
          text="Efficiently manage leads across channels with a customized system to stay ahead of the competition"
          className="mx-auto max-w-4xl font-light"
        />
        <Content>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-5 md:gap-12">
            {data.map((item, index) => (
              <Item item={item} key={index} />
            ))}
          </div>
          <div className="flex w-full flex-col items-center">
            <PlayVideo theme="m2" />
          </div>
        </Content>
      </Container>
    </Section>
  );
};

export default Feature;
