export const AutomateIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.707 0.494537H14.4125C13.9641 0.494537 13.5976 0.860944 13.5976 1.30938V7.02422H7.87734C7.4289 7.02422 7.06249 7.39063 7.06249 7.83907V14.3688H1.34765C0.899213 14.3688 0.532806 14.7352 0.532806 15.1836V48.6633C0.532806 49.1117 0.899213 49.4781 1.34765 49.4781H35.6422C36.0906 49.4781 36.457 49.1117 36.457 48.6633V42.9539H42.1719C42.6203 42.9539 42.9867 42.5875 42.9867 42.1391V36.4188H48.7016C49.15 36.4188 49.5164 36.0523 49.5164 35.6039V1.30938C49.5219 0.860944 49.1555 0.494537 48.707 0.494537ZM34.8273 42.1336V47.8484H2.16249V16.0094H34.8219V42.1336H34.8273ZM41.357 35.6039V41.3188H36.457V15.1891C36.457 14.7406 36.0906 14.3742 35.6422 14.3742H8.69765V8.65938H41.357V35.6039ZM47.8922 34.7891H42.9922V7.83907C42.9922 7.39063 42.6258 7.02422 42.1773 7.02422H15.2273V2.12422H47.8867V34.7891H47.8922Z"
        fill="url(#paint0_linear_2140_12026)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2140_12026"
          x1="25.0246"
          y1="0.494537"
          x2="25.0246"
          y2="49.4781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
      </defs>
    </svg>
  );
};
