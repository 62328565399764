export const ClosingIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 19.5H24M6 31.5H19M36.5 33V40.5H44.5M9.10927 47.5H27C27 47.5 21 39 25 32C30.9761 21.5418 40.5 25 40.5 25V14C40.5 14 31.5 14.9915 28.5 12C24.9024 8.41261 26.5 0.999998 26.5 0.999998H7.37398C6.45907 0.999998 5.53691 1.04043 4.6646 1.31636C3.94267 1.54471 3.08235 1.91765 2.5 2.5C1.88089 3.11911 1.43214 3.90857 1.11858 4.62229C0.588839 5.8281 0.5 7.16335 0.5 8.48039V39.358C0.5 41.1117 0.626186 42.9295 1.57801 44.4024C1.83571 44.8012 2.14214 45.1864 2.5 45.5C4.27769 47.058 6.74546 47.5 9.10927 47.5ZM49 38C49 44.0751 44.0751 49 38 49C31.9249 49 27 44.0751 27 38C27 31.9249 31.9249 27 38 27C44.0751 27 49 31.9249 49 38ZM29.5 0.982841V9.5C29.5 10.6046 30.3954 11.5 31.5 11.5H40.0172C40.1953 11.5 40.2846 11.2846 40.1586 11.1586L29.8414 0.84142C29.7154 0.715427 29.5 0.80466 29.5 0.982841Z"
        stroke="url(#paint0_linear_2140_12082)"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2140_12082"
          x1="24.75"
          y1="0.78244"
          x2="24.75"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
      </defs>
    </svg>
  );
};
