export const OrganiseIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2140_12033)">
        <path
          d="M8.1597 36.7621C5.57591 36.7621 3.46068 34.6603 3.46068 32.0631C3.46068 29.4659 5.56252 27.3641 8.1597 27.3641C10.7569 27.3641 12.8587 29.4659 12.8587 32.0631C12.8587 34.6603 10.7569 36.7621 8.1597 36.7621ZM8.1597 28.984C7.33916 28.9843 6.55236 29.3106 5.9724 29.8911C5.39244 30.4716 5.06683 31.2586 5.06718 32.0792C5.06754 32.8997 5.39383 33.6865 5.9743 34.2665C6.55476 34.8464 7.34183 35.172 8.16238 35.1717C8.98292 35.1713 9.76971 34.845 10.3497 34.2646C10.9296 33.6841 11.2552 32.897 11.2549 32.0765C11.2545 31.256 10.9282 30.4692 10.3478 29.8892C9.76731 29.3092 8.98024 28.9836 8.1597 28.984Z"
          fill="url(#paint0_linear_2140_12033)"
        />
        <path
          d="M12.7382 44.8482H4.25054C4.0765 44.8482 3.91585 44.7946 3.79536 44.7009C3.59723 44.6792 3.41402 44.5853 3.28069 44.4372C3.14735 44.289 3.07322 44.097 3.07243 43.8977V40.4571C3.07243 37.5654 5.42864 35.2226 8.30695 35.2226C11.1853 35.2226 13.5415 37.5788 13.5415 40.4571V44.0449C13.5415 44.4867 13.18 44.8482 12.7382 44.8482ZM4.66555 43.2417H11.935V40.4571C11.935 38.449 10.3017 36.8291 8.30695 36.8291C6.31221 36.8291 4.67894 38.4623 4.67894 40.4571V43.2417H4.66555ZM47.5592 36.7621C44.9754 36.7621 42.8601 34.6603 42.8601 32.0631C42.8601 29.4659 44.962 27.3641 47.5592 27.3641C50.1563 27.3641 52.2582 29.4659 52.2582 32.0631C52.2582 34.6603 50.1563 36.7621 47.5592 36.7621ZM47.5592 28.984C46.7386 28.9843 45.9518 29.3106 45.3719 29.8911C44.7919 30.4716 44.4663 31.2586 44.4666 32.0792C44.467 32.8997 44.7933 33.6865 45.3738 34.2665C45.9542 34.8464 46.7413 35.172 47.5618 35.1717C47.9681 35.1715 48.3704 35.0913 48.7457 34.9357C49.121 34.78 49.462 34.552 49.7491 34.2646C50.0363 33.9772 50.264 33.636 50.4194 33.2606C50.5747 32.8851 50.6545 32.4828 50.6544 32.0765C50.6542 31.6702 50.574 31.2679 50.4183 30.8926C50.2627 30.5173 50.0347 30.1764 49.7472 29.8892C49.4598 29.602 49.1187 29.3743 48.7432 29.219C48.3678 29.0637 47.9655 28.9838 47.5592 28.984Z"
          fill="url(#paint1_linear_2140_12033)"
        />
        <path
          d="M52.1377 44.8482H43.65C43.476 44.8482 43.3153 44.7946 43.1948 44.7009C42.9967 44.6792 42.8135 44.5853 42.6802 44.4372C42.5468 44.289 42.4727 44.097 42.4719 43.8977V40.4571C42.4719 37.5654 44.8281 35.2226 47.7064 35.2226C50.5848 35.2226 52.941 37.5788 52.941 40.4571V44.0449C52.941 44.4867 52.5795 44.8482 52.1377 44.8482ZM44.065 43.2417H51.3345V40.4571C51.3345 38.449 49.7012 36.8291 47.7064 36.8291C45.7117 36.8291 44.0784 38.4623 44.0784 40.4571V43.2417H44.065ZM41.923 18.689H14.2376C13.0461 18.689 12.0822 17.7251 12.0822 16.5336V2.15539C12.0822 0.963902 13.0461 0 14.2376 0H41.923C43.1145 0 44.0784 0.963902 44.0784 2.15539V16.547C44.0784 17.7385 43.1145 18.7024 41.923 18.7024V18.689ZM14.2376 1.6065C13.9431 1.6065 13.6887 1.84748 13.6887 2.15539V16.547C13.6887 16.8415 13.9297 17.0959 14.2376 17.0959H41.923C42.2176 17.0959 42.4719 16.8549 42.4719 16.547V2.15539C42.4719 1.86087 42.2309 1.6065 41.923 1.6065H14.2376ZM37.1571 47.1241H32.8329C32.3911 47.1241 32.0297 46.7626 32.0297 46.3208C32.0297 45.879 32.3911 45.5176 32.8329 45.5176H37.1571C37.4516 45.5176 37.6926 45.3435 37.6926 45.1293V24.218C37.6926 24.0038 37.4516 23.8298 37.1571 23.8298H19.0036C18.7091 23.8298 18.4681 24.0038 18.4681 24.218V45.1293C18.4681 45.3435 18.7091 45.5176 19.0036 45.5176H22.886C23.3278 45.5176 23.6892 45.879 23.6892 46.3208C23.6892 46.7626 23.3278 47.1241 22.886 47.1241H19.0036C17.8255 47.1241 16.8616 46.2271 16.8616 45.1293V24.218C16.8616 23.1203 17.5002 22.5 19.0036 22.5H37.1571C38.3352 22.5 39.2991 23.1203 39.2991 24.218V45.1293C39.2991 46.2271 38.3352 47.1241 37.1571 47.1241Z"
          fill="url(#paint2_linear_2140_12033)"
        />
        <path
          d="M27.8527 47.5391C25.1752 47.5391 22.9797 45.3569 22.9797 42.666C22.9797 41.7022 23.2655 40.7601 23.8009 39.9587C24.3364 39.1573 25.0975 38.5327 25.9879 38.1639C26.8783 37.7951 27.8581 37.6986 28.8034 37.8866C29.7487 38.0746 30.617 38.5387 31.2985 39.2203C31.98 39.9018 32.4441 40.7701 32.6322 41.7153C32.8202 42.6606 32.7237 43.6404 32.3549 44.5309C31.986 45.4213 31.3614 46.1824 30.5601 46.7178C29.7587 47.2533 28.8165 47.5391 27.8527 47.5391ZM27.8527 39.4129C26.0588 39.4129 24.5862 40.8721 24.5862 42.6794C24.5862 44.4867 26.0454 45.946 27.8527 45.946C29.66 45.946 31.1193 44.4867 31.1193 42.6794C31.1193 40.8721 29.66 39.4129 27.8527 39.4129Z"
          fill="url(#paint3_linear_2140_12033)"
        />
        <path
          d="M32.6321 56H23.7561C23.5821 56 23.4215 55.9464 23.2876 55.8527C23.0864 55.8359 22.8988 55.7439 22.7624 55.5951C22.6259 55.4462 22.5506 55.2514 22.5513 55.0495V51.4482C22.5513 48.4494 24.9878 46.0129 27.9866 46.0129C30.9854 46.0129 33.4219 48.4494 33.4219 51.4482V55.1967C33.4219 55.6385 33.0605 56 32.6187 56H32.6321ZM24.1712 54.3935H31.8288V51.4482C31.8288 50.9454 31.7298 50.4475 31.5374 49.983C31.345 49.5185 31.0629 49.0964 30.7074 48.7408C30.3518 48.3853 29.9298 48.1033 29.4652 47.9109C29.0007 47.7184 28.5028 47.6194 28 47.6194C27.4972 47.6194 26.9993 47.7184 26.5348 47.9109C26.0702 48.1033 25.6481 48.3853 25.2926 48.7408C24.9371 49.0964 24.655 49.5185 24.4626 49.983C24.2702 50.4475 24.1712 50.9454 24.1712 51.4482V54.3935ZM33.7432 35.7447L32.9668 33.027V27.1097C32.9668 26.8821 32.7793 26.6947 32.5517 26.6947H23.5687C23.3411 26.6947 23.1537 26.8821 23.1537 27.1097V33.027L22.3772 35.7447C22.2835 36.0124 22.4843 36.2936 22.7655 36.2936H33.3416C33.6228 36.2936 33.8236 36.0124 33.7298 35.7447H33.7432ZM24.0908 27.6185H32.0564V32.6522H24.0908V27.6185ZM27.0093 35.3832L27.1164 34.9147H29.0308L29.1379 35.3832H27.0093ZM29.8742 35.3832L29.6333 34.272C29.6333 34.272 29.5797 34.1917 29.5396 34.1917H26.6345C26.6345 34.1917 26.5408 34.2185 26.5408 34.272L26.2998 35.3832H23.4884L24.0239 33.4822H32.1635L32.699 35.3832H29.8742ZM17.6648 3.97609H38.4958V4.99953H17.6648V3.97609ZM20.6101 7.99953H35.497V8.99953H20.6101V7.99953ZM34.4577 13.9995H38.5008L38.4958 15.0342H34.4528L34.4577 13.9995Z"
          fill="url(#paint4_linear_2140_12033)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2140_12033"
          x1="8.1597"
          y1="27.3641"
          x2="8.1597"
          y2="36.7621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2140_12033"
          x1="27.6653"
          y1="27.3641"
          x2="27.6653"
          y2="44.8482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2140_12033"
          x1="32.5116"
          y1="0"
          x2="32.5116"
          y2="47.1241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2140_12033"
          x1="27.8527"
          y1="37.793"
          x2="27.8527"
          y2="47.5391"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2140_12033"
          x1="28.0828"
          y1="3.97609"
          x2="28.0828"
          y2="56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <clipPath id="clip0_2140_12033">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
