import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@/components/tippy';
import Button from '@/components/button';
import { CheckIcon, InfoIcon, MinusIcon, PlusIcon } from '@/assets/icons';
import { PriceShadow } from '@/assets/shadow';
import { Container, Content, Section, Title } from '@/components/section';
import { ChevronLeftIcon, ChevronRightIcon } from '@/assets/icons';
import MediaQuery from '@/utils/useResponsive';
import { useResponsive } from '@/utils/useResponsive';
import Counter from './counterV1';
import cx from 'classnames';
import { BOOK_DEMO_URL } from '@/config-global';
interface TableHead {
  text: string;
  value: string;
  label: string;
  recommended: boolean;
}
interface TableBody {
  text: string;
  values: (string | number | boolean)[];
  open?: boolean;
  children?: TableBody[];
  help?: string;
  isLast?: boolean;
  isChild?: boolean;
  isLastChild?: boolean;
}

interface DataProps {
  head: TableHead[];
  body: TableBody[];
}

const data: DataProps = {
  head: [
    // {
    //   text: '',
    //   label: 'Free',
    //   value: '£0 / month',
    //   recommended: false
    // },
    {
      text: '',
      label: 'Free',
      value: '£0',
      recommended: false
    },
    {
      text: '',
      label: 'Standard*',
      value: '£135 / month Or £1350 annually',
      recommended: true
    },
    {
      text: '',
      label: 'Premium*',
      value: '£190 / month Or £1900 annually ',
      recommended: false
    }
  ],
  body: [
    {
      text: '',
      values: [
        // 'Includes 2 units/month',
        'Up to 3 live marketing listings /month',
        'Includes 25 live marketing listings /month',
        'Includes 50 live marketing listings /month'
      ]
    },
    {
      text: '',
      values: [
        // 'Up to 2 live units per month, and then upgrade',
        '',
        'More than 25 live marketing listings /month Additional £1.35 per listing /month',
        'More than 50 live marketing listings /month Additional £3.5 per listing /month'
      ]
    },
    {
      text: '',
      values: [
        // 'Unlimited users & devices',
        '1 user & 2 devices',
        'Unlimited users & devices',
        'Unlimited users & devices'
      ]
    },
    {
      text: '',
      values: [
        // 'Email + Chatbot',
        'Email + Chatbot',
        'Email + Chatbot',
        'Email + Chatbot + Phone'
      ]
    },
    {
      text: 'Lead generation module',
      open: true,
      values: [],
      children: [
        {
          text: 'Custom branded property webpage',
          help: 'Our system automatically creates custom branded property website for prospective tenants to interact with AI chatbot.',
          values: [true, true, true]
        },
        {
          text: 'Automated & Customised email/instant message',
          help: 'Automatically generated and customized email or instant message with a link to your branded website',
          values: [true, true, true]
        },
        // {
        //   text: 'Customized branding email',
        //   help: 'Customized email with your branding embedded a link to your branded website',
        //   values: [true, true, true]
        // },
        // {
        //   text: 'Optional channel - Automated SMS',
        //   help: 'Automated SMS with a link to your website sent to a prospective tenant',
        //   values: ['£0.05 per SMS', '£0.05 per SMS', '£0.05 per SMS']
        // },
        {
          text: 'Interactive voice response',
          help: 'Interactive voice response in English can automatically handle customer leads from major portals via telephone call channel.',
          values: [false, false, true]
        }
      ]
    },
    {
      text: 'AI intelligent sale module',
      open: true,
      values: [],
      children: [
        {
          text: 'Sales Chatbot on website',
          help: 'Customised Nestflo AI Chatbot . Integrate with your main website, which can nurture warm leads on your main website, assisting in closing sales 24/7.',
          values: [false, true, true]
        },
        // {
        //   text: 'Live Chat',
        //   help: 'Our Livechat interface integrates with the main website, which enables your team and your website visitors to chat in real time.',
        //   values: [true, true, true]
        // },
        {
          text: 'Initial tenant enquiry',
          help: 'AI-powered virtual robot will utilize data to provide relevant, instant, and intelligent replies using generative AI capabilities.',
          values: [true, true, true]
        },
        {
          text: 'Pre-qualify prospective tenants',
          help: 'AI Chatbot can intelligently ask appropriate questions to pre-qualify prospective tenants 24/7.',
          values: [true, true, true]
        },
        {
          text: 'Customised criteria',
          help: 'Based on your branded property webpage, our AI will automatically set up pre-qualifying criteria, additionally, you can add, delete, and amend a new pre-qualifying condition.',
          values: [true, true, true]
        },
        {
          text: 'Score & rrade report',
          help: 'The AI-powered virtual robot will ask customised pre-qualifying questions to tenants and summarise their responses into a report with weighted scores and grades.',
          values: [true, true, true]
        },
        // {
        //   text: 'Recommend properties to prospective tenants',
        //   help: 'AI Chatbot automatically vet prospective tenants to match with a particular rental property.',
        //   values: [true, true, true]
        // },
        {
          text: 'Schedule management',
          help: 'Simply book a viewing on the Nestflo platform, and all scheduled viewings can be synchronized with your main calendar, such as Google calendar and Outlook.',
          values: [true, true, true]
        },
        // {
        //   text: 'Remind scheduled viewing',
        //   help: 'AI system automatically reminds tenants of a scheduled viewing.',
        //   values: [true, true, true]
        // },
        {
          text: 'Virtual viewings',
          help: 'Upload property videos or 360-degree VRs onto the Nestflo platform, and the Nestflo chatbot will automatically conduct virtual viewings for you.',
          values: [false, true, true]
        },
        {
          text: 'Deposit Payment',
          help: 'Provide your bank details on the Nestflo platform, and the Nestflo chatbot will automatically request that a pre-qualified tenant pay a holding deposit.',
          values: [false, true, true]
        },
        {
          text: 'Opportunity capture',
          help: 'Nestflo AI Chatbot can capture new landlord opportunities by asking customised questions to tenants or buyers 24/7.',
          values: [false, true, true]
        },
        {
          text: 'Outbound phone system',
          help: 'You can directly call a pre-qualified tenant from the Nestflo back office without additional hassle.',
          values: [false, false, true]
        },
        {
          text: 'Phone AI system',
          help: 'Nestflo AI can record phone conversations and provide AI-generated summaries of the discussions.',
          values: [false, false, true]
        }
      ]
    },
    {
      text: 'AI universal platform module',
      open: false,
      values: [],
      children: [
        {
          text: 'Data recording & displaying',
          help: 'All communications in lead generation module & AI intelligent sale module are recorded, easily retrieved, and displayed.',
          values: [true, true, true]
        },
        {
          text: 'Sale statistics',
          help: 'All communications in lead generation module and AI intelligent sale module are analysed and presented.',
          values: [true, true, true]
        },
        {
          text: 'Billing & accounts',
          help: 'This shows customer billing and accounts details.',
          values: [true, true, true]
        },
        // {
        //   text: 'Channel control',
        //   help: 'You can easily switch on/off any channels in Lead generation module and AI intelligent sale module.',
        //   values: [true, true, true]
        // },
        {
          text: 'Schedules management',
          help: 'All scheduled viewings in AI intelligent sale module are recorded, easily retrieved and viewed.',
          values: [true, true, true]
        },
        {
          text: 'AI IVR management system',
          help: 'You can customize your phone system by simply adding/deleting a short text in English.',
          values: [false, false, true]
        }
      ]
    },
    // {
    //   text: 'Sale platform',
    //   open: true,
    //   values: [],
    //   children: [
    //     {
    //       text: '',
    //       // values: ['Free Up to 2 Properties', '£75 / month', '£125 / month']
    //       values: [
    //         '£0 / month',
    //         '£80 / month Or £800 annually',
    //         '£135 / month Or £1350 annually ',
    //         '£190 / month Or £1900 annually'
    //       ]
    //     },
    //     {
    //       text: '',
    //       values: [
    //         'Includes 2 units/month',
    //         'Includes 10 units/month',
    //         'Includes 15 units/month',
    //         'Includes 20 units/month'
    //       ]
    //     },
    //     {
    //       text: '',
    //       values: [
    //         'Up to 2 live units per month, and then upgrade',
    //         '+ £1.35 Unit/month',
    //         '+ £1.35 Unit/month',
    //         '+ £3.5 Unit/month'
    //       ]
    //     }
    //     // {
    //     //   text: '',
    //     //   values: ['Book Demo', 'Book Demo', 'Book Demo', 'Book Demo']
    //     //   // isLast: true
    //     // }
    //     // {
    //     //   text: '',
    //     //   values: [
    //     //     'Prices exclude VAT in the United Kingdom, and all packages are subject to a single branch location.',
    //     //     '',
    //     //     '',
    //     //     ''
    //     //   ]
    //     // }
    //   ]
    // },
    {
      text: '',
      values: [
        // 'Book Demo',
        'Free to use',
        'Sign up',
        'Sign up'
      ],
      isLast: true
    }
  ]
};

const Row: React.FC<TableBody> = ({
  text,
  values,
  open,
  help,
  children,
  ...rest
}) => {
  const { isMobile } = useResponsive();
  const [isOpen, setIsOpen] = useState(open);

  return (
    <>
      {children && children?.length > 0 && (
        <tr className="relative">
          <th
            scope="colgroup"
            colSpan={isMobile ? 1 : 4}
            className="sticky left-4 z-30 bg-transparent"
          >
            <div
              className={cx(
                'flex items-center space-x-2 md:space-x-8 py-4 pl-4 text-sm relative [background:linear-gradient(90deg,_rgba(179,_48,_74,_0.5),_rgba(101,_35,_239,_0.5))]',
                {
                  'rounded-t-3xl': isOpen,
                  'rounded-3xl': !isOpen
                }
              )}
            >
              <span>{text}</span>
              <button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className="pr-2"
              >
                {isOpen ? (
                  <MinusIcon className="size-6" />
                ) : (
                  <PlusIcon className="size-6" />
                )}
              </button>
            </div>
            {!isOpen && (
              <div className="absolute left-6 right-10 h-px bg-[#BEAFF4]/10" />
            )}
          </th>
        </tr>
      )}
      {values && values.length > 0 && (
        <RowContent text={text} values={values} help={help} {...rest} />
      )}
      {isOpen &&
        children?.map((child, index) => (
          <RowContent
            key={index}
            isChild
            isLastChild={children.length === index + 1}
            {...child}
          />
        ))}
    </>
  );
};

const RowContent: React.FC<TableBody> = ({
  text,
  help,
  values,
  isLast,
  isChild,
  isLastChild
}) => {
  const shouldMerge =
    values[0] && values.slice(1).every((value) => value === '');

  const formatValue = (value: string | number | boolean) => {
    if (typeof value === 'string' && value.includes('Or')) {
      const parts = value.split(/ Or /);
      return (
        <>
          <div>{parts[0]}</div>
          <div className="text-sm">Or {parts[1]}</div>
        </>
      );
    }
    return value;
  };

  return (
    <tr
      className={cx('relative', {
        'h-full w-full [background:linear-gradient(90deg,_rgba(121,_7,_238,_0.1),_rgba(41,_46,_98,_0.2))]':
          isChild,
        'rounded-b-2xl': isLastChild
      })}
    >
      <th
        scope="row"
        className={cx(
          'sticky left-8 z-30 py-4 pl-0 text-sm font-normal leading-6 text-white lg:pl-4 lg:backdrop-blur-none',
          {
            '[background:#100929]': isChild,
            '[background:#050710]': !isChild
          }
        )}
      >
        <div className="flex items-center space-x-2">
          {help && (
            <Tippy content={<span>{help}</span>}>
              <InfoIcon className="size-4 cursor-pointer" />
            </Tippy>
          )}
          <span>{text}</span>
        </div>
        {!isLast && (
          <div className="absolute left-6 right-10 mt-4 h-px bg-[#BEAFF4]/10" />
        )}
      </th>

      {shouldMerge ? (
        <td colSpan={4} className="relative px-6 py-4 xl:px-8">
          <div className="text-gray-300 text-center text-sm leading-6">
            <span className="text-xl font-bold">*</span> {values[0]}
          </div>
        </td>
      ) : (
        values.map((value, index) => (
          <td key={index} className="relative px-6 py-4 xl:px-8">
            <div
              className={cx({
                'flex flex-col items-center justify-between': isLast
              })}
            >
              {isLast ? (
                <>
                  <div className="hidden items-center space-x-4">
                    <Counter index={index} />
                  </div>
                  <div className="flex w-full items-center justify-center">
                    <a
                      href="https://office.nestflo.ai/auth/login/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="table-bg mx-auto my-6 px-4 text-sm lg:px-8 xl:my-8 xl:px-10 xl:text-base">
                        {value}
                      </Button>
                    </a>
                  </div>
                </>
              ) : typeof value === 'string' ? (
                <div className="text-gray-300 text-center text-sm leading-6">
                  {formatValue(value)}
                </div>
              ) : (
                <>
                  {value === true ? (
                    <CheckIcon
                      className="mx-auto size-5 text-green"
                      aria-hidden="true"
                    />
                  ) : (
                    <MinusIcon
                      className="text-gray-500 mx-auto size-5"
                      aria-hidden="true"
                    />
                  )}
                </>
              )}
            </div>
          </td>
        ))
      )}
    </tr>
  );
};

// 在 Table 组件内部
const Table: React.FC<{
  data: DataProps;
  scrollRight: any;
  scrollLeft: any;
  current: number;
}> = ({ data, scrollLeft, scrollRight, current }) => {
  const { head, body } = data;

  // 管理显示提示的索引状态
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  // 处理表头数据，将“Or”后的内容进行特殊处理并存储在新属性中
  const processedHead = head.map((item) => {
    const orRegex = / Or (.+)/;
    const match = item.value.match(orRegex);
    let extraContent = null;
    if (match) {
      extraContent = match[1];
    }
    return {
      ...item,
      value: item.value.replace(orRegex, ''),
      extraContent
    };
  });

  // 悬浮提示文本
  const hoverText = '2 months free & save the hassle of paying monthly';

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .hover-text {
        white-space: nowrap; /* 默认不换行 */
        color: #ffffff; 
        background: rgba(0, 0, 0, 0.8); /* 半透明背景 */
        padding: 8px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* 增加阴影 */
      }

      @media (max-width: 768px) {
        .hover-text {
          white-space: normal;
          max-width: 150px;
          text-align: center;
        }
      }
     
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="isolate mt-20 min-w-[1024px] lg:block">
      <div className="relative -mx-2">
        <div
          className="absolute inset-x-5 inset-y-0 -z-10 grid grid-cols-5 gap-x-5 before:block md:grid-cols-4"
          aria-hidden="true"
        >
          {processedHead.map((item, index) => (
            <div
              key={index}
              className={cx(
                'h-full w-full rounded-3xl border border-b-[#4906d0]/50 border-l-[#150343] border-r-[#7907ee]/40  bg-darker/20 backdrop-blur',
                {
                  'border-t-transparent': item.recommended,
                  'border-t-[#150343]': !item.recommended
                }
              )}
            />
          ))}
        </div>
        <table className="w-full table-fixed border-separate border-spacing-x-6 text-left">
          <thead>
            <tr className="relative">
              <td className="sticky left-12 z-50 -mt-8 rounded-t-3xl bg-darker/50 backdrop-blur lg:bg-transparent">
                <MediaQuery maxWidth={450}>
                  <div className="flex flex-col items-center justify-center">
                    <div className="text-xl font-bold opacity-75">
                      {processedHead[current].label}
                    </div>
                    <div className="mt-4 flex w-full items-center justify-center space-x-4">
                      {current > 0 && (
                        <button onClick={scrollLeft}>
                          <ChevronLeftIcon className="size-8 text-white" />
                        </button>
                      )}
                      {current < processedHead.length - 1 && (
                        <button onClick={scrollRight}>
                          <ChevronRightIcon className="size-8 text-white" />
                        </button>
                      )}
                    </div>
                  </div>
                </MediaQuery>
              </td>

              {/* 显示表头 */}
              {processedHead.map((item, index) => (
                <th
                  key={index}
                  scope="col"
                  className={cx(
                    'relative rounded-b-3xl border-b border-b-purpleLight/50 px-4 py-6 lg:px-7 lg:pb-8 lg:pt-5',
                    {
                      'opacity-0 xs:opacity-100': index !== current
                    }
                  )}
                  // 为 Basic、Standard、Premium 添加鼠标移入/移出事件
                  onMouseEnter={() =>
                    index >= 0 && index <= 2 && setHoveredIndex(index)
                  }
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <div className="flex flex-col items-center text-center">
                    <div className="opacity-75">{item.label}</div>
                    <div className="table-title mt-2 text-xs font-medium uppercase tracking-widest xl:text-sm xl:tracking-[0.5em]">
                      {item.text}
                    </div>
                    <div className="mt-2 text-2xl font-medium tracking-tighter xl:mt-4 xl:text-4xl">
                      {item.value}
                      {item.extraContent && item.label !== 'Sale platform' && (
                        <div className="extra-content">
                          <span style={{ fontSize: '0.8em' }}>
                            Or
                            <br />
                            {item.extraContent}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* 悬浮显示提示信息 */}
                  {index > 0 && hoveredIndex === index && (
                    <div className="hover-text bg-black absolute bottom-full left-1/2 -translate-x-1/2 rounded-md p-2 text-xs text-white">
                      {hoverText}
                    </div>
                  )}

                  {item.recommended && (
                    <div className="table-bg absolute -inset-x-1 -top-8 -z-20 box-content size-full rounded-3xl px-1 pb-10">
                      <span className="inline-block w-full py-2 text-center text-xs font-medium uppercase tracking-[0.5em]">
                        Best value
                      </span>
                    </div>
                  )}
                </th>
              ))}
              <th className="md:hidden"></th>
            </tr>
          </thead>
          <tbody>
            {body.map((section, sectionIdx) => (
              <Row key={sectionIdx} {...section} />
            ))}
          </tbody>
        </table>
      </div>
      <p
        style={{
          opacity: 0.4,
          marginTop: 20,
          fontSize: 12
        }}
      >
        * Prices exclude VAT in the United Kingdom.
      </p>
    </div>
  );
};

const Price: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [current, setCurrent] = useState(0);
  const scrollLeft = () => {
    if (containerRef.current && current > 0) {
      containerRef.current.scrollBy({
        left: -193,
        behavior: 'instant'
      });
      setCurrent((prev) => prev - 1);
    }
  };

  const scrollRight = () => {
    if (containerRef.current && current < 3) {
      containerRef.current.scrollBy({
        left: 193,
        behavior: 'instant'
      });
      setCurrent((prev) => prev + 1);
    }
  };

  // 在客户端才添加 CSS 样式
  React.useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
   .extra-content {
        display: block;
        margin-top: 4px;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <Section id="link_5">
      <picture className="pointer-events-none absolute inset-x-0 -z-20">
        <PriceShadow className="h-auto w-full" />
      </picture>
      <Container>
        <Title
          text="Pricing"
          subTitle="Choose from a range of packages to suit your needs. Our experts will get Nestflo set up on your systems with no additional charges."
          className="font-medium"
          wrapperClassName="mx-auto w-full max-w-[875px]"
        />
        <Content>
          <div
            className="-mx-4 -my-2 overflow-x-hidden xs:overflow-x-auto sm:-mx-6 lg:-mx-8"
            ref={containerRef}
          >
            <div
              className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
              id="price"
            >
              <Table
                data={data}
                scrollLeft={scrollLeft}
                scrollRight={scrollRight}
                current={current}
              />
            </div>
          </div>
          <div className="mt-8 hidden w-full items-center justify-end md:text-right">
            <p className="font-thin">
              *No limitations of number of users and devices.
            </p>
          </div>
          <div className="mt-1 hidden w-full items-center justify-end md:text-right">
            <p className="font-thin">
              + When you use the Free package, you will NOT charge for the costs
              of using SMS, which means the package is FREE.
            </p>
          </div>
        </Content>
        {/*<div className="flex w-full flex-col items-center">*/}
        {/*  <a href={REGISTER_URL} target="_blank" rel="noreferrer">*/}
        {/*    <Cta>Free Trial </Cta>*/}
        {/*  </a>*/}
        {/*</div>*/}
      </Container>
    </Section>
  );
};

export default Price;
